import * as Routes from 'constants/Routes';
import * as React from 'react';
import { generatePath, NavLink as RouterNavLink, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { Nav, NavItem, NavLink, UncontrolledCollapse } from 'reactstrap';
import * as Clients from '../../../client/ApiClient';
import { DataTableButton } from '../../basic/DataTable';
import { tierIds } from './Constants';
import TierSummaryPage from './entities/generic/TierSummary';
import TabNavigation from './MappingContent';
import OptionsPage from './Options';
import ConversionSummaryPage from './ConversionSummary'
import MergeSummaryPage from './mergetool/MergeSummary'
import { ApplicationState } from 'store';
import { connect } from 'react-redux';
import { missingEntitiesTier2, missingEntitiesTier3, missingEntitiesTier4, missingEntitiesTier5 } from './Constants';
import * as SourceSystem from 'constants/SourceSystem';
import * as DOMPurify from 'isomorphic-dompurify';
import './Mapping.scss';

type MappingPageProps = RouteComponentProps<{
    conversionId: string,
}> & {
    currentUser: Clients.IUserLoginDetailsModel | undefined,
};

type TabName = 'source' | 'staging' | 'target';

class StatisticsViewModel {
    entityType?: string | undefined;
    sourceData?: boolean;
}

interface MappingPageState {
    conversionName?: string;
    tab: TabName;
    options: { [key: string]: Clients.ConversionOptionValueModel } | undefined;
    items: StatisticsViewModel[];
    conversion: Clients.ConversionModel | null;
}

class MappingPage extends React.Component<MappingPageProps, MappingPageState>{
    private readonly client: Clients.ConversionClient = new Clients.ConversionClient();
    private readonly options: Clients.ConversionOptionsClient = new Clients.ConversionOptionsClient();
    private readonly statisticsClient: Clients.StatisticsClient = new Clients.StatisticsClient();
    private readonly tierIds: string[] = ['tier0', 'tier1', 'tier4', 'tier5']

    constructor(props: Readonly<MappingPageProps>) {
        super(props)
        this.state = {
            conversionName: undefined,
            conversion: null,
            tab: 'staging',
            options: undefined,
            items: [],
        };
    }

    componentDidMount() {
        this.client.get(this.props.match.params.conversionId)
            .then(response => {
                if (response) {
                    this.setState({
                        conversionName: response!.description,
                        conversion: response
                    }, () => {
                        if (this.state.conversion!.conversionTypeId.toUpperCase() === SourceSystem.epicToAMS360Id) this.getStatistics();
                    })
                }
            })
            .catch(error => { })
        this.options.get(this.props.match.params.conversionId)
            .then(values => {
                if (values) {
                    const options = values!.reduce(
                        (result, current) => ({ ...result, [current.id!]: current, }), {});
                    this.setState({ options });
                }
            })
            .catch(error => { });
    }

    getStatistics = () => {
        const promises = [];
        for (let tierId of this.tierIds) {
            promises.push(this.statisticsClient.get(this.props.match.params.conversionId, tierId))
        }
        Promise.all(promises)
            .then((data) => { if (data) { this.processData(data) } })
            .catch(err => console.log(err));
    }

    processData(statisticsData: any) {
        if (statisticsData) {
            const statistics = [...statisticsData[0], ...statisticsData[1], ...missingEntitiesTier2, ...missingEntitiesTier3, ...statisticsData[2], ...missingEntitiesTier4, ...statisticsData[3], ...missingEntitiesTier5];
            const items = []
            for (let stat of statistics) {
                items.push({
                    "entityType": stat.entityType,
                    "sourceData": stat.mapped !== null && stat.notMapped !== null
                })
            }
            this.setState({ items })
        }
    }

    toggle = (tab: TabName) => {
        if (this.state.tab !== tab) {
            this.setState({ tab: tab })
        }
    }

    selected = (tab: TabName) => this.state.tab === tab;

    generateEntityPath = (tierId: string, sectionId: string): string => {
        return generatePath(Routes.entityLocationTemplate, {
            conversionId: this.props.match.params.conversionId,
            tierId: tierId,
            sectionId: sectionId,
            tabId: this.state.tab,
        });
    }

    generateTierSummaryPath = (tierId: string): string => {
        return generatePath(Routes.tierSummaryLocationTemplate, {
            conversionId: this.props.match.params.conversionId,
            tierId: tierId
        })
    }

    generateConversionSummaryPath = (): string => {
        return generatePath(Routes.conversionSummaryLocationTemplate, {
            conversionId: this.props.match.params.conversionId
        })
    }

    generateEntityMergePath = (sectionId: string): string => {
        return generatePath(Routes.entityMergeLocationTemplate, {
            conversionId: this.props.match.params.conversionId,
            sectionId: sectionId,
            tabId: this.state.tab,
        });
    }

    generateMergeSummaryPath = (): string => {
        return generatePath(Routes.mergeSummaryLocationTemplate, {
            conversionId: this.props.match.params.conversionId
        })
    }

    epicNav = () => {
        const params = this.props.match.params;
        const tier1 = tierIds.tier1;
        const tier2 = tierIds.tier2;
        const tier3 = tierIds.tier3;
        const tier4 = tierIds.tier4;
        const tier5 = tierIds.tier5;
        const items = this.state.items
        return <div id='mapping-sidebar-container'>
            <div id='mapping-sidebar'>
                <Nav vertical>
                    <NavItem id='mapping-sidebar-header'>
                        <h4>
                            <a className='flatlink' href={sanitizeUrl(`/conversion/${params.conversionId}/edit`)}>
                                {this.state.conversionName}
                            </a>
                        </h4>
                        <DataTableButton icon='assignment' id='conversion' title='All Tiers Summary'
                            className='tier-summary-icon'
                            action={id => this.props.history.push(this.generateConversionSummaryPath())}
                        />
                    </NavItem>
                    <NavItem className='nav-header'>
                        <NavLink className='nav-toggler' id="tier0Toggler">
                            Prerequisites
                        </NavLink>
                        <UncontrolledCollapse toggler="tier0Toggler">
                            <Nav vertical>
                                {items[0] && items[0]!.sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath('tier0', 'employeetypes')}>Employee Types</NavLink>
                                    </NavItem>}
                            </Nav>
                        </UncontrolledCollapse>
                    </NavItem>
                    <NavItem className='nav-header'>
                        <NavLink className='nav-toggler' id="tier1Toggler">
                            Tier 1 Entities
                        </NavLink>
                        <DataTableButton icon='assignment' id='tier1' title='Tier Summary'
                            className='tier-summary-icon'
                            action={id => this.props.history.push(this.generateTierSummaryPath(tier1))}
                        />
                        <UncontrolledCollapse toggler="tier1Toggler">
                            <Nav vertical>
                                {items[1] && items[1].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'employees')}>Employees</NavLink>
                                    </NavItem>
                                }
                                {items[2] && items[2].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'company')}>Companies</NavLink>
                                    </NavItem>
                                }
                                {items[3] && items[3].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'vendor')}>Vendors</NavLink>
                                    </NavItem>
                                }
                                {items[4] && items[4].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'brokers')}>Brokers</NavLink>
                                    </NavItem>
                                }
                                {items[5] && items[5].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'lineOfBusiness')}>Line of Business</NavLink>
                                    </NavItem>
                                }
                                {items[6] && items[6].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'policystatustypes')}>Policy Status Types</NavLink>
                                    </NavItem>
                                }
                                {items[7] && items[7].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'policytransactiontypes')}>Policy Transaction Types</NavLink>
                                    </NavItem>
                                }
                                <NavItem className='subNav'>
                                    <NavLink tag={RouterNavLink} className='nav-toggler' to={this.generateEntityPath(tier1, 'businessUnits')} id="businessUnitsToggler">Business Units</NavLink>
                                </NavItem>
                                <UncontrolledCollapse toggler="businessUnitsToggler">
                                    <Nav vertical className='subBackground'>
                                        {items[8] && items[8].sourceData &&
                                            <NavItem>
                                                <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'division')}>Divisions</NavLink>
                                            </NavItem>
                                        }
                                        {items[9] && items[9].sourceData && this.state.options && this.state.options["61d5689e-56fe-4098-b964-7a797c540c10"].value !== 'N' &&
                                            <NavItem>
                                                <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'branch')}>Branches</NavLink>
                                            </NavItem>
                                        }
                                        {items[10] && items[10].sourceData &&
                                            <NavItem>
                                                <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'department')}>Departments</NavLink>
                                            </NavItem>
                                        }
                                        {items[11] && items[11].sourceData && this.state.options && this.state.options["42a30566-b350-4e93-b0a1-d76919db2c6b"].value !== 'N' &&
                                            <NavItem>
                                                <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier1, 'group')}>Groups</NavLink>
                                            </NavItem>
                                        }
                                    </Nav>
                                </UncontrolledCollapse>
                            </Nav>
                        </UncontrolledCollapse>
                    </NavItem>
                    <NavItem className='nav-header'>
                        <NavLink className='nav-toggler' id="tier2Toggler">
                            Tier 2 Entities
                        </NavLink>
                        <DataTableButton icon='assignment' id='tier2' title='Tier Summary'
                            className='tier-summary-icon'
                            action={id => this.props.history.push(this.generateTierSummaryPath(tier2))}
                        />
                        <UncontrolledCollapse toggler="tier2Toggler">
                            <Nav vertical>
                                {items[12] && items[12].sourceData &&
                                    <>
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier2, 'clients')}>Clients</NavLink>
                                    </NavItem>
                                    </>
                                }
                            </Nav>
                        </UncontrolledCollapse>
                    </NavItem>
                    <NavItem className='nav-header'>
                        <NavLink className='nav-toggler' id="tier3Toggler">
                            Tier 3 Entities
                        </NavLink>
                        <DataTableButton icon='assignment' id='tier3' title='Tier Summary'
                            className='tier-summary-icon'
                            action={id => this.props.history.push(this.generateTierSummaryPath(tier3))}
                        />
                        <UncontrolledCollapse toggler="tier3Toggler">
                            <Nav vertical>
                                {items[13] && items[13].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier3, 'policyHeaders')}>Policy Headers</NavLink>
                                    </NavItem>
                                }
                                {items[14] && items[14].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier3, 'clientcontacts')}>Client Contacts</NavLink>
                                    </NavItem>
                                }
                            </Nav>
                        </UncontrolledCollapse>
                    </NavItem>
                    <NavItem className='nav-header'>
                        <NavLink className='nav-toggler' id="tier4Toggler">
                            Tier 4 Entities
                        </NavLink>
                        <DataTableButton icon='assignment' id='tier4' title='Tier Summary'
                            className='tier-summary-icon'
                            action={id => this.props.history.push(this.generateTierSummaryPath(tier4))}
                        />
                        <UncontrolledCollapse toggler="tier4Toggler">
                            <Nav vertical>
                                <NavItem className='subNav'>
                                    <NavLink className='nav-toggler' id="claims">Claims</NavLink>
                                </NavItem>
                                <UncontrolledCollapse toggler="claims">
                                    <Nav vertical className='subBackground'>
                                        {items[15] && items[15].sourceData &&
                                            <NavItem>
                                                <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier4, 'kindofloss')}>Kinds of Loss</NavLink>
                                            </NavItem>
                                        }
                                    </Nav>
                                </UncontrolledCollapse>
                                {items[16] && items[16].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier4, 'actionTypes')}>Action Types<br />(Activity, Image, Suspense)</NavLink>
                                    </NavItem>
                                }
                                {items[25] && items[25].sourceData &&
                                    <NavItem>
                                        <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier4, 'notes')}>Notes</NavLink>
                                    </NavItem>
                                }
                            </Nav>
                        </UncontrolledCollapse>
                    </NavItem>
                    <NavItem className='nav-header'>
                        <NavLink tag={RouterNavLink} className='nav-toggler' id="policyToggler" to={this.generateEntityPath(tier5, 'policyDetails')}>Policy Details</NavLink>
                        <DataTableButton icon='assignment' id='tier5' title='Tier Summary'
                            className='tier-summary-icon'
                            action={id => this.props.history.push(this.generateTierSummaryPath(tier5))}
                        />
                        <UncontrolledCollapse toggler="policyToggler">
                            <Nav vertical>
                                {((items[17] && items[17].sourceData) || (items[18] && items[18].sourceData) || (items[19] && items[19].sourceData)) &&
                                    <NavItem className='subNav'>
                                        <NavLink tag={RouterNavLink} className='nav-toggler' id="pAutoToggler" to={this.generateEntityPath(tier5, 'personalAuto')}>Personal Auto</NavLink>
                                    </NavItem>
                                }
                                {((items[17] && items[17].sourceData) || (items[18] && items[18].sourceData) || (items[19] && items[19].sourceData)) &&
                                    <UncontrolledCollapse toggler="pAutoToggler">
                                        <Nav vertical className='subBackground'>
                                            {items[17] && items[17].sourceData &&
                                                <NavItem>
                                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'personalautoantilockBrakes')}>Antilock Brakes</NavLink>
                                                </NavItem>
                                            }
                                            {items[18] && items[18].sourceData &&
                                                <NavItem>
                                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'personalautobodytype')}>Body Types</NavLink>
                                                </NavItem>
                                            }
                                            {items[19] && items[19].sourceData &&
                                                <NavItem>
                                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'personalautocoveragecodes')}>Coverage Codes</NavLink>
                                                </NavItem>
                                            }
                                        </Nav>
                                    </UncontrolledCollapse>
                                }
                                {((items[20] && items[20].sourceData) || (items[21] && items[21].sourceData)) &&
                                    <NavItem className='subNav'>
                                        <NavLink tag={RouterNavLink} className='nav-toggler' id="fireToggler" to={this.generateEntityPath(tier5, 'dwellingFire')}>Dwelling Fire</NavLink>
                                    </NavItem>
                                }
                                {((items[20] && items[20].sourceData) || (items[21] && items[21].sourceData)) &&
                                    <UncontrolledCollapse toggler="fireToggler">
                                        <Nav vertical className='subBackground'>
                                            {items[20] && items[20].sourceData &&
                                                <NavItem>
                                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'hodfdfformtype')}>Form Types</NavLink>
                                                </NavItem>
                                            }
                                            {items[21] && items[21].sourceData &&
                                                <NavItem>
                                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'hodfdfcoveragecode')}>Coverage Codes</NavLink>
                                                </NavItem>
                                            }
                                        </Nav>
                                    </UncontrolledCollapse>
                                }
                                {((items[22] && items[22].sourceData) || (items[23] && items[23].sourceData)) &&
                                    <NavItem className='subNav'>
                                        <NavLink tag={RouterNavLink} className='nav-toggler' id="homeToggler" to={this.generateEntityPath(tier5, 'homeowner')}>Homeowners</NavLink>
                                    </NavItem>
                                }
                                {((items[22] && items[22].sourceData) || (items[23] && items[23].sourceData)) &&
                                    <UncontrolledCollapse toggler="homeToggler">
                                        <Nav vertical className='subBackground'>
                                            {items[22] && items[22].sourceData &&
                                                <NavItem>
                                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'hodfhoformtype')}>Form Types</NavLink>
                                                </NavItem>
                                            }
                                            {items[23] && items[23].sourceData &&
                                                <NavItem>
                                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'hodfhocoveragecode')}>Coverage Codes</NavLink>
                                                </NavItem>
                                            }
                                        </Nav>
                                    </UncontrolledCollapse>
                                }
                                <NavItem className='subNav'>
                                    <NavLink tag={RouterNavLink} className='nav-toggler' id="compropToggler" to={this.generateEntityPath(tier5, 'commercialProperty')}>Commercial Property</NavLink>
                                </NavItem>
                                <UncontrolledCollapse toggler="compropToggler">
                                    <Nav vertical className='subBackground'>
                                        <NavItem>
                                            <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'cpcauseofloss')}>Causes Of Loss</NavLink>
                                        </NavItem>
                                    </Nav>
                                </UncontrolledCollapse>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'commercialAuto')}>Commercial Auto</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'workersComp')}>Workers Comp</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'commercialUmbrella')}>Commercial Umbrella</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'equipmentfloater')}>Equipment Floater</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'generalliability')}>General Liability</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'personalumbrella')}>Personal Umbrella</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'commercialapplication')}>Commercial Application</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityPath(tier5, 'businessownerpolicies')}>Business Owner Policies</NavLink>
                                </NavItem>
                            </Nav>
                        </UncontrolledCollapse>
                    </NavItem>
                </Nav>
            </div>
            <div>
                <Route exact path={Routes.tierSummaryLocationTemplate} component={TierSummaryPage} />
                <Route exact path={Routes.entityLocationTemplate} render={(props) => <TabNavigation imagePath={this.state.conversion!.imagePath} options={this.state.options} {...props} />} />
                <Route path={Routes.optionsLocationTemplate} component={OptionsPage} />
                <Route exact path={Routes.conversionSummaryLocationTemplate} component={ConversionSummaryPage} />
            </div>
        </div>
    }

    //merge tool
    ams360Nav = () => {
        const params = this.props.match.params;
        const sanitizedConversionId = DOMPurify.sanitize(params.conversionId);
        return <div id='mapping-sidebar-container'>
            <div id='mapping-sidebar'>
                <Nav vertical>
                    <NavItem id='mapping-sidebar-header'>
                        <h4>
                            <a className='flatlink' href={`/conversion/${sanitizedConversionId}/edit`}>
                                {this.state.conversionName}
                            </a>
                        </h4>
                        <DataTableButton icon='assignment' id='conversion' title='All Tiers Summary'
                            className='tier-summary-icon'
                            action={id => this.props.history.push(this.generateMergeSummaryPath())}
                        />
                    </NavItem>
                    <NavItem className='nav-header'>
                        <Nav vertical>
                            <NavItem className='nav-header'>
                                <NavLink className='nav-toggler' id="ab">
                                    A-B Entities
                                </NavLink>
                            </NavItem>
                            <UncontrolledCollapse toggler="ab">
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('acquisitioncodes')}>Acquisition Codes</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('activitysuspenseactiontypes')}>Activity Suspense Action Types</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('attachedforms')}>Attached Forms</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('billingtransactions')}>Billing Transactions</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('bodytypes')}>Body Types</NavLink>
                                </NavItem>
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('branchmerge')}>Branches</NavLink>*/}
                                {/*</NavItem>*/}
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('brokersmerge')}>Brokers</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('businessorigins')}>Business Origins</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('businesstypes')}>Business Types</NavLink>
                                </NavItem>
                                <NavItem className='subNav'>
                                    <NavLink tag={RouterNavLink} className='nav-toggler' to={this.generateEntityMergePath('businessUnits')} id="businessUnitsToggler">Business Units</NavLink>
                                </NavItem>
                                <UncontrolledCollapse toggler="businessUnitsToggler">
                                    <Nav vertical className='subBackground'>
                                        <NavItem>
                                            <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('divisionmerge')}>Divisions</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('branchmerge')}>Branches</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('departmentmerge')}>Departments</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('groupmerge')}>Groups</NavLink>
                                        </NavItem>
                                    </Nav>
                                </UncontrolledCollapse>
                            </UncontrolledCollapse>
                            {/*<NavItem>*/}
                            {/*    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('chargecategories')}>Charge Categories</NavLink>*/}
                            {/*</NavItem>*/}
                            <NavItem className='nav-header'>
                                <NavLink className='nav-toggler' id="c">
                                    C Entities
                                </NavLink>
                            </NavItem>
                            <UncontrolledCollapse toggler="c">
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('claimpaidbytypes')}>Claim Paid By Types</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('claimpaymenttypes')}>Claim Payment Types</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('companymerge')}>Companies</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('companypersonnelpositions')}>Company Personnel Positions</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('companyplans')}>Company Plans</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('constructiontypes')}>Construction Types</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('contacttypes')}>Contact Types</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('coveragecodes')}>Coverage Codes</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('coverageoptions')}>Coverage Options</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('customernotations')}>Customer Notations</NavLink>
                                </NavItem>
                            </UncontrolledCollapse>
                            {/*<NavItem>*/}
                            {/*    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('dataporterfiles')}>Data Porter Files</NavLink>*/}
                            {/*</NavItem>*/}
                            <NavItem className='nav-header'>
                                <NavLink className='nav-toggler' id="dh">
                                    D-H Entities
                                </NavLink>
                            </NavItem>
                            <UncontrolledCollapse toggler="dh">
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('departmentmerge')}>Departments</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('divisionmerge')}>Divisions</NavLink>*/}
                                {/*</NavItem>*/}
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('documentcategories')}>Document Categories</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('documentclassifications')}>Document Classifications</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('documenttypes')}>Document Types</NavLink>
                                </NavItem>
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('downloadtransactiontypes')}>Download Transaction Types Types</NavLink>*/}
                                {/*</NavItem>*/}
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('employeemerge')}>Employees</NavLink>
                                </NavItem>
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('groupmerge')}>Groups</NavLink>*/}
                                {/*</NavItem>*/}
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('heattypes')}>Heat Types</NavLink>
                                </NavItem>
                            </UncontrolledCollapse>
                            <NavItem className='nav-header'>
                                <NavLink className='nav-toggler' id="ir">
                                    I-R Entities
                                </NavLink>
                            </NavItem>
                            <UncontrolledCollapse toggler="ir">
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('incomegroups')}>Income Groups</NavLink>*/}
                                {/*</NavItem>*/}
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('interesttypes')}>Interest Types</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('jobtitles')}>Job Titles</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('kindoflossmerge')}>Kinds of Loss</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('lineofbusinessmerge')}>Line of Business</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('occupations')}>Occupations</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('paymentplans')}>Payment Plans</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('personalpropertyclasses')}>Personal Property Classes</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('policysubnotations')}>Policy Sub Notations</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('profiles')}>Profile Questions</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('relationships')}>Relationships</NavLink>
                                </NavItem>
                            </UncontrolledCollapse>
                            <NavItem className='nav-header'>
                                <NavLink className='nav-toggler' id="sz">
                                    S-Z Entities
                                </NavLink>
                            </NavItem>
                            <UncontrolledCollapse toggler="sz">
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('subjectsofinsurance')}>Subjects of Insurance</NavLink>
                                </NavItem>
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('textsetups')}>Text Setups</NavLink>*/}
                                {/*</NavItem>*/}
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('transactiontypes')}>Transaction Types</NavLink>*/}
                                {/*</NavItem>*/}
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('typeentities')}>Type Entities</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('unlicensedexcludeddrivers')}>Unlicensed Excluded Drivers</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('violations')}>Violations</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RouterNavLink} to={this.generateEntityMergePath('x-refs')}>X-Refs</NavLink>
                                </NavItem>
                            </UncontrolledCollapse>
                        </Nav>
                    </NavItem>
                </Nav>
            </div>
            <div>
                <Route exact path={Routes.entityLocationTemplate} render={(props) => <TabNavigation {...props} />} />
                <Route path={Routes.optionsLocationTemplate} component={OptionsPage} />
                <Route exact path={Routes.mergeSummaryLocationTemplate} component={MergeSummaryPage} />
            </div>
        </div >
    }

    public render() {
        return (
            <>
                {this.state.conversion && this.state.conversion!.conversionTypeId.toUpperCase() === SourceSystem.epicToAMS360Id && this.epicNav()}
                {this.state.conversion && this.state.conversion!.conversionTypeId.toUpperCase() === SourceSystem.ams360ToAMS360Id && this.ams360Nav()}
            </>
        )
    }
}

export default withRouter(connect(
    (state: ApplicationState) => {
        return {
            currentUser: state.authentication && state.authentication.currentUser
        }
    })(MappingPage));

/** BEGIN: Angular URL Sanitizer */

const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;

/** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
const DATA_URL_PATTERN = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i;

function _sanitizeUrl(url: string): string {
    url = String(url);
    if (url === "null" || url.length === 0 || url === "about:blank") return "about:blank";
    if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) return url;

    return `unsafe:${url}`;
}

export function sanitizeUrl(url = "about:blank"): string {
    return _sanitizeUrl(String(url).trim());
}

/** END: Angular URL Sanitizer */